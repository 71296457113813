<template>
    <div class="preview_area show_sidebar" v-if="modelValue">
        <div class="global_setting">
            <button class="close_btn modal-window-close-button" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <div class="categories">
                <div class="cat_item show">
                    <h4 @click="toggleMenu($event)">Opt-Ins<i class="fas fa-angle-down ml-auto"></i></h4>
                    <ul>
                        <li :class="params.category === 'newsletter' ? 'active' : ''" @click="handleNewsletterCategorie('newsletter')">Newsletter</li>
                        <li :class="params.category === 'lead-magnet' ? 'active' : ''" @click="handleNewsletterCategorie('lead-magnet')"> Lead Magnet</li>
                    </ul>
                </div>
            </div>
            <div class="tabs_content">
                <form class="newsletter-template">
                    <div class="content_area">
                        <div class="content_wpr">
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>{{ params.category === 'newsletter' ? 'Newsletter' : 'Lead Magnet' }} Template</h2>
                                </div>
                                <swiper :slidesPerView="'auto'" :spaceBetween="30" :navigation='true' class="tab_slider">
                                    <swiper-slide><span @click="handleNewsletterType('all')" :class="{ active: params.type === 'all' }">All Templates</span></swiper-slide>
                                    <swiper-slide><span @click="handleNewsletterType('favourite')" :class="{ active: params.type === 'favourite' }">Favorites</span></swiper-slide>
                                    <swiper-slide><span @click="handleNewsletterType('draft')" :class="{ active: params.type === 'draft' }">Drafts</span></swiper-slide>
                                </swiper>
                                <div class="listing_loader" v-show="newsletterLoader && params.category == 'newsletter'"><quote-loader /></div>
                                <div v-show="!newsletterLoader">
                                    <ul class="library_template" v-if="newsletterTemplates.total && params.category == 'newsletter'">
                                        <template v-for="(newsletter, n) in newsletterTemplates.data" :key="n">
                                            <li>
                                                <div class="newsletter_card">
                                                    <img :src="newsletter.thumbnail" :alt="newsletter.name">
                                                    <span class="fav_btn" :class="{ active: newsletter.favourite }" @click="handleUpdateFavourite(newsletter)" v-if="params.type != 'draft'"><i class="far fa-heart"></i><i class="fas fa-heart"></i></span>
                                                    <div class="action_btn">
                                                        <button type="button" class="preview" @click="handleFullPreview(newsletter)">Preview</button>
                                                        <button type="button" class="use" @click="handleNewsletterEdit(newsletter)">Use Template</button>
                                                    </div>
                                                </div>
                                                <h4>{{ newsletter.name }}</h4>
                                            </li>
                                        </template>
                                    </ul>
                                    <ul class="library_template" v-if="!newsletterTemplates.total && params.category == 'newsletter'">
                                        <li class="coming-soon">
                                            <h2 v-if="params.type == 'favourite'">No favorite templates!</h2>
                                            <h2 v-if="params.type == 'draft'">No draft templates!</h2>
                                        </li>
                                    </ul>
                                </div>
                                <ul class="library_template" v-if="params.category == 'lead-magnet'">
                                    <li class="coming-soon">
                                        <h2>Coming Soon!</h2>
                                    </li>
                                </ul>

                                <div class="pagination" v-show="newsletterTemplates.total && params.type == 'draft'">
                                    <pagination v-model="params.page" :pages="newsletterTemplates.last_page" @update:modelValue="handlePagination" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="action_wpr">
                        <button type="button" class="btn cancel_btn" @click="closeModal()">Exit</button>
                        <button type="button" class="btn save_btn">Save</button>
                    </div>
                </form>
            </div>
        </div>
        <newsletter-edit v-model="newsletterEdit" :selected-newsletter="selectedNewsletter" :refresh-newsletter="refreshNewsletter" />
        <full-preview :step="1" v-model="fullPreview" :newsletter="selectedNewsletter" />
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import { mapState, mapActions } from 'vuex'
    import SwiperCore, { Navigation } from 'swiper'

    const NewsletterEdit = defineAsyncComponent(() => import('@/pages/optin/NewsletterForm'))
    const FullPreview = defineAsyncComponent(() => import('@/pages/optin/components/FullPreview'))

    SwiperCore.use([Navigation])
    import 'swiper/swiper-bundle.min.css'

    export default{
        name: 'NewsletterLibrary',

        data () {
            return {
                preview: true,
                params: {
                    page: 1,
                    per_page: 6,
                    type: 'all',
                    category: 'newsletter',
                },
                fullPreview: false,
                selectedNewsletter: {},
                newsletterEdit: false,
                leadMagnetSetting: {},
            }
        },

        components:{
            NewsletterEdit,
            FullPreview,
            Swiper,
            SwiperSlide,
        },

        props: {
            modelValue: Boolean,
            refreshNewsletter: Function,
        },

        emit: ['update:modelValue'],

        watch: {
            modelValue (val) {
                if (val) {
                    const vm = this;

                    vm.params = {
                                    page: 1,
                                    per_page: 6,
                                    type: 'all',
                                    category: 'newsletter',
                                };

                    vm.getNewsletterTemplates(vm.params);

                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: {
            ...mapState({
                newsletterTemplates: state => state.newsletterModule.newsletterTemplates,
                newsletterLoader: state => state.newsletterModule.newsletterTemplatesLoader,
            }),
        },

        methods: {
            ...mapActions({
                getNewsletterTemplates: 'newsletterModule/getNewsletterTemplates',
                updateFavourite: 'newsletterModule/updateFavourite',
            }),

            toggleMenu (e) {
                let el = e.currentTarget.closest('.cat_item');

                if (el.classList.contains('show')) {
                    el.classList.remove('show');
                } else {
                    el.classList.add('show');
                }
            },

            closeModal () {
                const vm = this;

                vm.refreshNewsletter();
                vm.$emit('update:modelValue', false);
            },

            handleNewsletterType (type) {
                const vm = this;

                vm.params.type = type;

                if (vm.params && vm.params.category == 'newsletter') {
                    vm.params.page      = 1;
                    vm.params.per_page  = 6;
                    vm.getNewsletterTemplates(vm.params);
                }
            },

            handleNewsletterCategorie (category) {
                const vm = this;

                vm.params.page      = 1;
                vm.params.per_page  = 6;
                vm.params.category  = category;

                vm.getNewsletterTemplates(vm.params);
            },

            handleFullPreview (newsletter) {
                const vm = this;

                vm.selectedNewsletter = newsletter;
                vm.fullPreview        = true;
            },

            handleNewsletterEdit (newsletter) {
                const vm = this;

                if (newsletter.category == 'newsletter') {
                    vm.selectedNewsletter = newsletter;
                    vm.newsletterEdit  = true;
                } else if (newsletter.category == 'lead-magnet') {
                    vm.selectedNewsletter = newsletter;
                    vm.leadMagnetSetting  = true;
                }
            },

            handleUpdateFavourite (newsletter) {
                const vm   = this;
                const form = { id: newsletter.id, favourite: newsletter.favourite ? 0 : 1 };

                vm.updateFavourite(form).then((result) => {
                    if (result) {
                        vm.getNewsletterTemplates(vm.params);
                    }
                });
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;

                vm.getNewsletterTemplates(vm.params);
            },
        }
    }
</script>

<style scoped>
    .newsletter-template {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    .tab_slider {
        padding: 15px 20px;
        margin: 0 -20px;
    }
    .categories {
        padding: 60px 20px 20px 20px;
        width: 250px;
        border-right: 1px solid #e9e9e9;
    }

    .categories .cat_item {
        margin-bottom: 8px;
    }

    .categories .cat_item h4 {
        font-size: 14px;
        line-height: 19px;
        font-weight: 500;
        color: #121525;
        padding: 15px 0;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .categories .cat_item h4 i {
        color: #5a5a5a;
        transition: all 0.3s ease-in-out;
    }

    .categories .cat_item.show h4 i {
        transform: rotate(-180deg);
    }

    .categories .cat_item h4 span  {
        position: relative;
        margin-left: -5px;
    }

    .categories .cat_item h4 span i {
        transform: scaleX(-1) !important;
        margin-right: 15px;
    }

    .categories .cat_item ul {
        position: relative;
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }

    .categories .cat_item ul:before  {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        bottom: 0;
        border-left: 1px solid #e9e9e9;
    }

    .categories .cat_item.show ul {
        max-height: 100vh;
    }

    .categories ul li {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #5a5a5a;
        padding: 15px 20px;
        cursor: pointer;
        position: relative;
    }

    .categories ul li.active {
        background: #fafafa;
        color: #2f7eed;
    }

    .categories li.active:after  {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        bottom: 0;
        border-left: 2px solid #a1c8ff;
    }

    .global_setting .preview_content  {
        position: relative;
        background: #fff;
        transition: all 0.3s ease-in-out;
        z-index: 4;
        margin-right: -40%;
    }

    .preview_content .cell  {
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
        position: relative;
        margin: 30px auto;
    }


    .preview_content .cell:after,
    .preview_content .cell:before {
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }

    .preview_content .cell:before {
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }

    .preview_content .cell:after {
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }

    .preview_content .cell .content_area {
        height: 610px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #fafafb;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .preview_content .cell .content_area img {
        opacity: 0.4;
    }

    .library_template {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
    }

    .library_template li {
        width: 33.333%;
        line-height: 0;
        padding: 15px;
    }

    .library_template li .newsletter_card {
        position: relative;
        background: #d9d9d9;
        border-radius: 10px;
        border-radius: 10px;
        overflow: hidden;
    }

    .library_template li img {
        max-width: 100%;
        height: auto;
    }

    .library_template li .fav_btn {
        position: absolute;
        right: 10px;
        top: 10px;
        color: #fff;
        text-shadow: 0 0 3px rgba(0,0,0,0.3);
        cursor: pointer;
        font-size: 18px;
        z-index: 1;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    .library_template li .fav_btn i.fas {
        display: none;
    }

    .library_template li .fav_btn.active i.far {
        display: none;
    }

    .library_template li .fav_btn.active i.fas {
        display: block;
    }

    .library_template li h4 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        margin: 15px 0 0 0;
    }

    .library_template li .action_btn {
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin: 0;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    .library_template li .action_btn button {
        width: 140px;
        padding: 12px 20px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        font-family: 'Inter', sans-serif;
        color: #121525;
        background: #fff;
        text-transform: uppercase;
        cursor: pointer;
        border-radius: 20px;
    }

    .library_template li .action_btn button.preview {
        border-right: 1px solid #e9e9e9;
    }

    .library_template li .action_btn button.use {
        background: #f2a31d;
        color: #fff;
    }

    .library_template li:hover .action_btn, .library_template li:hover .fav_btn {
        opacity: 1;
    }

    @media(max-width: 1399px) {
        .library_template li{
            width: 33.333%;
        }
    }

    li.coming-soon {
        width: 100%;
        display: flex;
        min-height: 60vh;
        justify-content: center;
        align-items: center;
    }

    li.coming-soon h2 {
        font-size: 30px;
        line-height: 36px;
        color: #121525;
        font-weight: 400;
    }
</style>
